import { useContext } from 'react';

import { APIErrorContext, IError } from './APIErrorProvider';

function useAPIError(): IError {
  const { message, type, showDialog, addError, removeError } = useContext(APIErrorContext);
  return { message, type, showDialog, addError, removeError };
}

export default useAPIError;
