import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useAuth } from 'oidc-react';
import { useState } from 'react';

import RolesDialog from './RolesDialog/RolesDialog';

const MoreActionsPopover = (): JSX.Element => {
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [rolesDialogOpen, setRolesDialogOpen] = useState<boolean>(false);
  const handleOpenRolesDialog = () => {
    setRolesDialogOpen(true);
    handleClose();
  };

  const handleLogoutClick = () => {
    auth.userManager.signoutRedirect();
    auth.userManager.removeUser();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleOpenRolesDialog}>Roles</MenuItem>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>

      <RolesDialog
        open={rolesDialogOpen}
        onClose={() => {
          setRolesDialogOpen(false);
        }}
      />
    </>
  );
};
export default MoreActionsPopover;
