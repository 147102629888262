/**
 * Determines the url of the remote api based on the given window location.
 *
 * The expected URL pattern for a production environment is: {protocol}://{app}.{customer}.saas.zeb-it.de
 * The expected URL pattern for a stage environment is: {protocol}://{app}.{stage}-{customer}.saas.zeb-it.de
 *
 * The api url for any environment is: {protocol}://api.{app}.{customer}.saas.zeb-it.de
 *
 * @param location the current window location
 * @returns the url of the remote api
 */
export function determineAPIUrl(location: Location, legacy?: boolean): string {
  const protocol = location.protocol;
  const host = location.host;
  if (legacy) {
    return `${protocol}//api.${host}`;
  }
  return `${protocol}//${host}`;
}

/**
 * Check wheter the application is deployed by a development server.
 *
 * @returns true if the application is in development mode
 */
export function isDevEnvironment(): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}
