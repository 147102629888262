import { Icon, Stack } from '@mdi/react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ZebLogo = (props: any) => {
  const viewBox = '0 0 66.61 34';
  const zebLogo =
    'M40.83 17.53c0-5.68-4-10.43-9.63-10.43-6.06 0-9.93 5-9.93 10.64 0 6.4 4.59 10.61 10.69 10.61a11.1 11.1 0 008.37-3.66l-3.11-3.12a7.2 7.2 0 01-5.14 2.36 5.36 5.36 0 01-5.51-4.84h14.14a9.42 9.42 0 00.12-1.56zm-14-2.44a4.42 4.42 0 014.37-3.57 4.07 4.07 0 014 3.57zM56.6 7.1A9 9 0 0050.84 9V0h-5.47v27.93h5.26v-1.64a8.53 8.53 0 005.68 2.06A10.27 10.27 0 0064 24.9a11 11 0 002.6-7.16c.01-6.01-4.44-10.64-10-10.64zm-.66 16.29a5.4 5.4 0 01-5.62-5.61 5.3 5.3 0 015.62-5.6 5.61 5.61 0 010 11.21zM0 7.52l2.65 4.62 8.04.01-4.41 6.33 2.81 4.82 8.73-12.42V7.52H0zM9.09 23.3l2.65 4.63h6.92V23.3H9.09z';
  return (
    <Stack {...props} viewBox={viewBox}>
      <Icon {...props} path={zebLogo} size={props.size} />
    </Stack>
  );
};

export default ZebLogo;
