import PersonIcon from '@mui/icons-material/Person';
import { Button, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useAuth } from 'oidc-react';
import { useIntl } from 'react-intl';

import { determineInitials } from '../../utils/InitialUtils';

export default function UserInformation() {
  const { formatMessage: f } = useIntl();
  const auth = useAuth();

  const handleLogin = () => {
    auth.signIn();
  };

  function username(): string {
    return auth?.userData?.profile.name ?? '';
  }

  return (
    <>
      <Avatar
        sx={{
          width: 40,
          height: 40,
          fontSize: 15,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        {username() ? determineInitials(username()) : <PersonIcon />}
      </Avatar>

      {username() ? (
        <Typography variant="body1">{username()}</Typography>
      ) : (
        <Button variant="text" color="primary" onClick={handleLogin}>
          {f({ id: 'pleaselogin.message' })}
        </Button>
      )}
    </>
  );
}
