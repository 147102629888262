/* eslint-disable no-console */
import { Box, Card, CardContent, CardHeader, styled, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useNavigate } from 'react-router';

import { axiosInstance, getAppConfig } from './api/restFacade';
import backgroundImage from './assets/images/zeb-bg.png';
import MoreActionsPopover from './components/MoreActionsPopover/MoreActionsPopover';
import PasswordFieldAndMessage from './components/PasswordGenerator/PasswordFieldAndMessage';
import UserInformation from './components/UserInformation/UserInformation';
import ZebLogo from './components/ZebLogo/ZebLogo';
import { APIErrorProvider } from './Dialogs/error/APIErrorProvider';
import ErrorDialog from './Dialogs/error/ErrorDialog';
import messagesDE from './i18n/de/messages.json';
import messagesEN from './i18n/en/messages.json';
import zebTheme, { primaryColor } from './theme/zebTheme';
import { IAppConfig } from './types/restModel';
import { determineAPIUrl } from './utils/environmentUtils';

const POST_LOGOUT_REDIRECT_URI = 'https://zeb-control.com/de-DE';

const Background = styled('div')({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  zIndex: 0,
});

const Layout = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// load localization files (client-side)
const currentLanguage = navigator.language.split(/[-_]/)[0];
const messages: { [key: string]: Record<string, string> } = {
  de: messagesDE,
  en: messagesEN,
};

export default function App(): React.ReactElement {
  const [authProviderConfig, setAuthProviderConfig] = useState<AuthProviderProps | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getAppConfig()
      .then((result) => {
        // set baseUrl for all further calls to api-prefix from app config endpoint
        axiosInstance.defaults.baseURL = `${determineAPIUrl(window.location)}${result?.['api-path-prefix']}`;

        const config = buildAuthProviderConfig(result);
        setAuthProviderConfig(config);
      })
      .catch((err) => {
        console.error('Error while trying to configure authentication provider.', JSON.stringify(err));

        console.debug('Performing second attempt on legacy api baseUrl');
        axiosInstance.defaults.baseURL = determineAPIUrl(window.location, true);

        getAppConfig()
          .then((result) => {
            const config = buildAuthProviderConfig(result);
            setAuthProviderConfig(config);
          })
          .catch((err) => {
            console.error(
              'Error while trying to configure authentication provider with legacy api.',
              JSON.stringify(err),
            );
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildAuthProviderConfig = (appConfig: IAppConfig | null): AuthProviderProps => {
    const authority = appConfig?.['idp-well-known-configuration'].includes('.well-known/openid-configuration')
      ? appConfig['idp-well-known-configuration'].split('.well-known/openid-configuration')[0]
      : '';

    const authProviderConfig: AuthProviderProps = {
      authority: authority,
      clientId: appConfig ? appConfig['client-id'] : '',
      scope: 'profile email openid',
      responseType: 'code',
      redirectUri: window.location.href,
      autoSignIn: true,
      automaticSilentRenew: true,
      postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
    };
    return authProviderConfig;
  };

  const onSignInCallback = () => {
    navigate('/');
  };

  return (
    <>
      {authProviderConfig ? (
        <AuthProvider {...authProviderConfig} onSignIn={onSignInCallback}>
          <ThemeProvider theme={zebTheme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
                <APIErrorProvider>
                  <Background>
                    <Layout>
                      <Card
                        elevation={2}
                        sx={{
                          height: 480,
                          borderRadius: 3,
                        }}>
                        <CardHeader sx={{ pt: 1, pb: 0 }} action={<MoreActionsPopover />} />
                        <CardContent
                          sx={{
                            pt: 0,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                          }}>
                          <ZebLogo color={primaryColor} size={4} />

                          <Box sx={{ mt: 4 }}>
                            <UserInformation />
                          </Box>

                          <Box sx={{ mt: 6 }}>
                            <PasswordFieldAndMessage />
                          </Box>
                        </CardContent>
                      </Card>
                    </Layout>
                  </Background>
                  <ErrorDialog />
                </APIErrorProvider>
              </IntlProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </AuthProvider>
      ) : null}
    </>
  );
}
