export function determineInitials(name: string): string {
  const res = name.split(' ');

  if (res.length == 1) {
    return firstLetter(res[0]);
  } else if (res.length > 1) {
    return firstLetter(res[0]) + firstLetter(res[res.length - 1]);
  }
  return '';
}

function firstLetter(letters: string): string {
  if (letters.length > 0) {
    return letters.charAt(0).toUpperCase();
  }
  return '';
}
