import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';

import useAPIError from './useAPIError';

const ErrorDialog = () => {
  const theme = useTheme();
  const error = useAPIError();

  const handleOk = () => {
    error.removeError();
  };

  return (
    <div>
      <Dialog open={error.showDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{error.type ? 'Error' : 'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{error.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleOk}
            sx={{
              borderColor: theme.palette.primary.main,
              margin: theme.spacing(0),
              color: theme.palette.grey[500],
              padding: theme.spacing(0),
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorDialog;
